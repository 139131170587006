<template>
  <div class="w-full">
    <div style="padding-top:10px; padding-bottom:10px w-full">
      <div class="flex flex-col items-center w-full">
        <div class="flex flex-col items-center w-full p-1 space-y-1 ">
          <div class="flex flex-row w-full max-w-sm space-x-3">
            <div class="flex flex-col w-full bg-gray-200">
              <b-form-group class="mb-0">
                <b-input-group size="sm">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="search"
                    v-model="compFilter"
                    placeholder="Search"
                    debounce="500"
                  />
                </b-input-group>
              </b-form-group>
            </div>
            <div class="flex flex-col w-full">
              <div class="flex flex-col">
                <b-form-group
                  label="By"
                  label-for="search-by-select"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-select
                      id="search-by-select"
                      v-model="filterBy"
                      :options="filterOptions"
                    >
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="flex flex-row max-w-sm space-x-1">
            <div>
              <label>Mailed From</label>
              <b-input-group
                label="Search By"
                label-for="From-input-mailed-date"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-form-input
                  id="from-input-mailed-date"
                  v-model="mailedDate.from"
                  size="sm"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                  show-decade-nav
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="mailedDate.from"
                    size="sm"
                    show-decade-nav
                    button-only
                    right
                    locale="en-US"
                    aria-controls="from-input-mailed-date"
                    ><template #button-content>
                      <feather-icon icon="CalendarIcon"/></template
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div>
              <label>Mailed To</label>
              <b-input-group
                label-for="to-input-mailed-date"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-form-input
                  id="to-input-mailed-date"
                  v-model="mailedDate.to"
                  size="sm"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                  show-decade-nav
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="mailedDate.to"
                    size="sm"
                    show-decade-nav
                    button-only
                    right
                    locale="en-US"
                    aria-controls="to-input-mailed-date"
                    ><template #button-content>
                      <feather-icon icon="CalendarIcon"/></template
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
          <div class="flex flex-row max-w-sm space-x-1">
            <div>
              <label>Exported From</label>
              <b-input-group
                label="Search By"
                label-for="From-input-exported-date"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-form-input
                  id="from-input-exported-date"
                  v-model="exportedDate.from"
                  size="sm"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                  show-decade-nav
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="exportedDate.from"
                    size="sm"
                    show-decade-nav
                    button-only
                    right
                    locale="en-US"
                    aria-controls="from-input-exported-date"
                    ><template #button-content>
                      <feather-icon icon="CalendarIcon"/></template
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div>
              <label>Exported To</label>
              <b-input-group
                label-for="to-input-exported-date"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-form-input
                  id="to-input-exported-date"
                  v-model="exportedDate.to"
                  size="sm"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                  show-decade-nav
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="exportedDate.to"
                    size="sm"
                    show-decade-nav
                    button-only
                    right
                    locale="en-US"
                    aria-controls="to-input-exported-date"
                    ><template #button-content>
                      <feather-icon icon="CalendarIcon"/></template
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>

          <div class="flex flex-row w-full max-w-sm px-1">
            <export-history-filter-checkbox @updateIncludes="updateIncludes" />
          </div>
        </div>
      </div>
      <div v-if="$can('get', 'accountAdmin')" class="bg-gray-200">
        <div class="text-xs italic pl-1">* admin only</div>
        <div class="w-full flex flex-row justify-end pb-1">
          <b-form-group
            label="user: "
            label-for="users-choice"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0 w-64 mr-1"
          >
            <b-input-group size="sm">
              <b-form-select
                id="users-choice"
                v-model="users"
                :options="userOptions"
              >
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import ExportHistoryFilterCheckbox from "./ComplExportHistoryFilterCheckbox"
import {
  BFormDatepicker,
  BFormInput,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect
} from "bootstrap-vue"
export default {
  components: {
    ExportHistoryFilterCheckbox,
    BFormDatepicker,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormSelect
  },
  props: {
    filter: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      // SORT FILTER PAGINATE SEARCH VARS
      users: "All Users",
      userOptions: [],
      includes: [
        "mailed",
        "disposed",
        "served",
        "garnished",
        "hearing",
        "filed"
      ],
      filterBy: "case_number",
      filterOptions: [
        { value: "filename", text: "File Name" },
        { value: "case_number", text: "Case Number" },
        { value: "defendant", text: "defendant(s)" },
        { value: "plaintiff", text: "Plaintiff(s)" }
      ],
      compFilter: this.filter,
      mailedDate: { to: "", from: "" },
      exportedDate: { to: "", from: "" },

      // SELECTION OPTIONS
      items: [
        { exportdate: "", mailed: true, types: "type", totals: "total" },
        { exportdate: "", mailed: true, types: "type", totals: "total" },
        { exportdate: "", mailed: true, types: "type", totals: "total" },
        { exportdate: "", mailed: true, types: "type", totals: "total" },
        { exportdate: "", mailed: true, types: "type", totals: "total" },
        { exportdate: "", mailed: true, types: "type", totals: "total" },
        { exportdate: "", mailed: true, types: "type", totals: "total" }
      ],
      fields: [
        {
          key: "exportdate",
          label: "Export Date",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "mailed",
          label: "Mailed",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "types",
          label: "Types"
        },
        {
          key: "totals",
          label: "Totals",
          sortable: true
        }
      ]
    }
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    users(newValue) {
      this.$emit("updateFilters", this.pack(this.userDict[newValue]))
    },
    // eslint-disable-next-line no-unused-vars
    compFilter(newValue) {
      this.$emit("updateFilter", this.compFilter)
    },
    // eslint-disable-next-line no-unused-vars
    filterBy(newValue) {
      this.$emit("updateFilters", this.pack())
    },
    // eslint-disable-next-line no-unused-vars
    filter(newValue) {
      this.compFilter = newValue
      this.$emit("updateFilters", this.pack())
    },
    mailedDate: {
      // eslint-disable-next-line no-unused-vars

      handler(nv) {
        if (nv.to && nv.from) {
          this.$emit("updateFilters", this.pack())
        }
      },
      deep: true
    },

    exportedDate: {
      // eslint-disable-next-line no-unused-vars
      handler(nv) {
        if (nv.to && nv.from) {
          this.$emit("updateFilters", this.pack())
        }
      },
      deep: true
    }
  },
  async created() {
    var l1 = this
    if (l1.$can("get", "accountAdmin")) {
      await l1.$AuthAxios.instance
        .get("/api-access/exports/export-users/", {})

        .then(
          // eslint-disable-next-line no-unused-vars
          await function(res) {
            res.data.names.unshift("All Users")
            l1.userOptions = res.data.names
            l1.userDict = res.data.namesDict
          }
        )
        .catch(function(error) {
          console.log(error)
        })
    }
  },

  methods: {
    updateIncludes(includes) {
      this.includes = includes
      this.$emit("updateFilters", this.pack())
    },
    pack(users = false) {
      if (!users) {
        users = this.users
      }
      return {
        users: users,
        filterBy: this.filterBy,
        filter: this.filter,
        mailDate: this.mailedDate,
        exportDate: this.exportedDate,
        includes: this.includes
      }
    }
  }
}
</script>

