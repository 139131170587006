<template>
  <div class="flex flex-col w-full">
    <div class="flex flex-col items-center justify-around md:flex-row">
      <div class="w-full max-w-sm lg:w-1/2">
        <b-overlay no-center :show="showStatsModal" rounded="sm">
          <template #overlay>
            <feather-icon
              icon="RefreshCwIcon"
              class="position-absolute"
              style="top: 0; right: 0"
            />
          </template>
          <div class="border rounded-lg shadow-sm">
            <div class="w-full font-bold text-center bg-gray-200 rounded-t-lg">
              My Historical Mailing Statistics
            </div>
            <div class="">
              <div>
                <div class="w-full font-bold bg-gray-100">
                  Total Records Mailed
                </div>
                <div class="ml-1">
                  <div class="flex flex-row">
                    <div class="w-3/4">Garnished</div>
                    <div class="w-1/4 pr-1 mr-1 text-right">
                      {{ stats.total_records_mailed.garnish }}
                    </div>
                  </div>
                  <div class="flex flex-row">
                    <div class="w-3/4">Disposed</div>
                    <div class="w-1/4 pr-1 mr-1 text-right">
                      {{ stats.total_records_mailed.disposed }}
                    </div>
                  </div>
                  <div class="flex flex-row">
                    <div class="w-3/4">Hearing</div>
                    <div class="w-1/4 pr-1 mr-1 text-right">
                      {{ stats.total_records_mailed.hearing }}
                    </div>
                  </div>
                  <div class="flex flex-row">
                    <div class="w-3/4">Served</div>
                    <div class="w-1/4 pr-1 mr-1 text-right">
                      {{ stats.total_records_mailed.served }}
                    </div>
                  </div>
                  <div class="flex flex-row">
                    <div class="w-3/4 border-b-2">Filed</div>
                    <div class="w-1/4 pr-1 mr-1 text-right border-b-2">
                      {{ stats.total_records_mailed.filed }}
                    </div>
                  </div>

                  <div class="flex flex-row">
                    <div class="w-3/4">Subtotal</div>
                    <div class="w-1/4 pr-1 mr-1 text-right">
                      {{ stats.total_records_mailed.subtotal }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full font-bold bg-gray-100">Mailings Over Time</div>
              <div class="ml-1">
                <div class="flex flex-row">
                  <div class="w-3/4">Last 30 Days</div>
                  <div class="w-1/4 pr-1 mr-1 text-right">
                    {{ stats.mailings_over_time.last_30 }}
                  </div>
                </div>
                <div class="flex flex-row">
                  <div class="w-3/4">List 60 Days</div>
                  <div class="w-1/4 pr-1 mr-1 text-right">
                    {{ stats.mailings_over_time.last_60 }}
                  </div>
                </div>
                <div class="flex flex-row">
                  <div class="w-3/4">Last 90 Days</div>
                  <div class="w-1/4 pr-1 mr-1 text-right">
                    {{ stats.mailings_over_time.last_90 }}
                  </div>
                </div>
                <div class="flex flex-row">
                  <div class="w-3/4 ">Last 12 Mos</div>
                  <div class="w-1/4 pr-1 mr-1 text-right">
                    {{ stats.mailings_over_time.last_12_mos }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full pr-1 text-xs text-right">
            * Includes count of combined addresses.
          </div>
        </b-overlay>
      </div>

      <div class="bg-gray-100 border rounded-lg shadow-sm lg:w-1/2">
        <export-history-table-filters
          :filter="filter"
          class="mt-2"
          @updateFilters="updateFilters"
          v-on="$listeners"
        />
      </div>
    </div>
    <div>
      <export-history-table
        :users="users"
        :file-name="fileName"
        :filter="filter"
        :filter-by="filterBy"
        :includes="includes"
        :mail-date="mailDate"
        :export-date="exportDate"
        class="mt-2"
        v-on="$listeners"
      />
    </div>
  </div>
</template>



<script>
import { BOverlay } from "bootstrap-vue"
import ExportHistoryTable from "./ComplExportHistoryTable"
import ExportHistoryTableFilters from "./ComplExportHistoryTableFilters"
export default {
  components: {
    BOverlay,
    ExportHistoryTable,
    ExportHistoryTableFilters
  },
  props: {
    showStatsModal: {
      type: Boolean,
      required: true
    },
    fileName: {
      type: String,
      required: true
    },
    filter: {
      type: String,
      required: true
    },
    stats: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      includes: [
        "mailed",
        "disposed",
        "served",
        "garnished",
        "hearing",
        "filed"
      ],
      mailDate: { to: "", from: "" },
      exportDate: { to: "", from: "" },

      filterBy: "filename",
      users: "All Users"
    }
  },

  methods: {
    updateFilters(nv) {
      this.users = nv.users
      this.filterBy = nv.filterBy
      this.filters = nv.filter
      this.exportDate = nv.exportDate
      this.mailDate = nv.mailDate
      this.includes = nv.includes
    }
  }
}
</script>

