<template>
  <div class="w-full">
    <div class="flex flex-row justify-between w-full pr-1">
      <div v-if="expanded" class="pl-10 text-3xl Bold">Export Details</div>
      <div v-else class="pl-10 text-3xl Bold">Mailings</div>
      <div v-if="!expanded" class="flex flex-row pr-10">
        <div class="w-48 mb-1 mr-2">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="mr-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </div>
      </div>
    </div>
    <div>
      <export-overlay
        :busy="busy"
        :busy-message="busyMessage"
        :heading="heading"
      >
        <b-overlay :show="showAreYouSure">
          <template v-if="areYouSureType.eType === 'deleteExport'" #overlay>
            <div
              ref="dialogexport"
              tabindex="-1"
              role="dialog"
              aria-modal="false"
              aria-labelledby="form-confirm-label"
              class="w-full text-center"
            >
              <div v-if="areYouSureType.data.item.mailed.mailed">
                <div class="mb-2 text-2xl text-center underline text-danger">
                  <strong
                    :id="
                      `${areYouSureType.data.item.filename}-form-confirm-label w-full`
                    "
                    >WARNING !! ARE YOU SURE YOU WANT TO DELETE ?</strong
                  >
                </div>
                <div class="w-full mb-2 text-xl text-center text-warning">
                  <strong
                    :id="
                      `${areYouSureType.data.item.filename}-form-confirm-label w-full`
                    "
                  >
                    <h4 class="mb-5 text-gray-800">
                      THIS MAILING IS MARKED UNDER YOUR
                      {{ $store.getters["auth/accountTypeAllCaps"] }}'S
                      COMPLIANCE RECORDS! If you have mailed letters on this
                      export and you proceede to delete this file, all records
                      associated will be lost and you will no longer be in
                      compliance with accurate record keeping as required by
                      State and Federal authorities. Furthermore, your future
                      exports will be unaware of these letters mailed and all
                      valid filtering and sorting will no longer work. YOUR
                      FUTURE EXPORTS WILL CONTAIN DUPLICATES!
                    </h4>
                    <p class="italic">
                      ** IT IS CRUCIAL THAT YOU ONLY SELECT 'YES' IF THE RECORDS
                      GENERATED FROM THE EXPORT WERE NOT MAILED.
                    </p>
                  </strong>
                </div>
                <div class="">
                  <b-button
                    size="lg"
                    variant="outline-danger"
                    class="mr-3"
                    @click="showAreYouSure = false"
                  >
                    NO GO BACK!
                  </b-button>

                  <b-button
                    size="lg"
                    variant="outline-success"
                    @click="deleteExport(areYouSureType.data)"
                    >YES</b-button
                  >
                </div>
              </div>
              <div v-else>
                <div class="w-full mb-5 text-xl text-center text-warning">
                  <strong
                    :id="
                      `${areYouSureType.data.item.filename}-form-confirm-label w-full`
                    "
                  >
                    <h4 class="mb-5 text-gray-800">
                      Are you sure you want to delete this export?
                    </h4>
                  </strong>
                </div>
                <div class="">
                  <b-button
                    size="lg"
                    variant="outline-danger"
                    class="mr-3"
                    @click="showAreYouSure = false"
                  >
                    NO
                  </b-button>

                  <b-button
                    size="lg"
                    variant="outline-success"
                    @click="deleteExport(areYouSureType.data)"
                    >YES</b-button
                  >
                </div>
              </div>
            </div>
          </template>
          <template
            v-else-if="areYouSureType.eType === 'updateMailed'"
            #overlay
          >
            <div
              ref="dialogexport"
              tabindex="-1"
              role="dialog"
              aria-modal="false"
              aria-labelledby="form-confirm-label"
              class="w-full text-center"
            >
              <!-- REMOVING FROM MAILED -->
              <div
                v-if="!tempTableData[areYouSureType.data.index].mailed.mailed"
              >
                <div class="mb-5 text-2xl text-center underline text-danger">
                  <strong
                    :id="
                      `${areYouSureType.data.item.filename}-form-confirm-label w-full`
                    "
                    >WARNING !! ARE YOU SURE YOU WANT TO MARK EXPORT AS NOT
                    MAILED ?</strong
                  >
                </div>
                <div class="w-full mb-2 text-xl text-center text-warning">
                  <strong
                    :id="
                      `${areYouSureType.data.item.filename}-form-confirm-label w-full`
                    "
                  >
                    <h4 class="mb-2 text-gray-800">
                      THIS MAILING IS MARKED UNDER YOUR
                      {{ $store.getters["auth/accountTypeAllCaps"] }}'S
                      COMPLIANCE RECORDS! If you have mailed letters on this
                      export and you proceede to set as NOT MAILED, you will no
                      longer be in compliance with accurate record keeping as
                      required by State and Federal authorities. Furthermore,
                      your future exports will be unaware of these letters
                      mailed and all valid filtering and sorting will no longer
                      work. THIS MEANS YOUR FUTURE EXPORTS MAY CONTAIN
                      DUPLICATES!
                    </h4>
                    <p class="italic">
                      ** IT IS CRUCIAL THAT YOU ONLY SELECT YES IF THE RECORDS
                      GENERATED FROM THE EXPORT WERE NOT MAILED.
                    </p>
                  </strong>
                </div>
                <div class="">
                  <b-button
                    size="lg"
                    variant="outline-danger"
                    class="mr-3"
                    @click="
                      showAreYouSure = false
                      tempTableData[
                        areYouSureType.data.index
                      ].mailed.mailed = false
                      tempTable = true
                      $refs.table.refresh()
                    "
                  >
                    NO GO BACK!
                  </b-button>

                  <b-button
                    size="lg"
                    variant="outline-success"
                    @click="updateMailed(areYouSureType.data)"
                    >YES</b-button
                  >
                </div>
              </div>
              <div v-else>
                <div class="mb-5 text-2xl text-center underline text-danger">
                  <strong
                    :id="
                      `${areYouSureType.data.item.filename}-form-confirm-label w-full`
                    "
                    >WARNING !! ARE YOU SURE YOU WANT TO MARK EXPORT AS MAILED
                    ?</strong
                  >
                </div>
                <div class="w-full mb-2 text-xl text-center text-warning">
                  <strong
                    :id="
                      `${areYouSureType.data.item.filename}-form-confirm-label w-full`
                    "
                  >
                    <h4 class="mb-2 text-gray-800">
                      THIS MAILING WILL BECOME PART OF YOUR
                      {{ $store.getters["auth/accountTypeAllCaps"] }}'S
                      COMPLIANCE RECORDS! If you have mailed letters on this
                      export and you proceede to set as MAILED, you will no
                      longer be in compliance with accurate record keeping as
                      required by State and Federal authorities. Furthermore,
                      your future exports will be filtered and sorted based on
                      these records. THIS MEANS YOUR FUTURE EXPORTS MAY EXCLUDE
                      THESE RECORDS!
                    </h4>
                    <p class="italic">
                      ** IT IS CRUCIAL THAT YOU ONLY SELECT YES IF THE RECORDS
                      GENERATED FROM THE EXPORT WERE MAILED.
                    </p>
                  </strong>
                </div>
                <div class="">
                  <b-button
                    size="lg"
                    variant="outline-danger"
                    class="mr-3"
                    @click="
                      showAreYouSure = false
                      tempTableData[
                        areYouSureType.data.index
                      ].mailed.mailed = false
                      tempTable = true
                      $refs.table.refresh()
                    "
                  >
                    NO GO BACK!
                  </b-button>

                  <b-button
                    size="lg"
                    variant="outline-success"
                    @click="updateMailed(areYouSureType.data)"
                    >YES</b-button
                  >
                </div>
              </div>
            </div>
          </template>
          <template
            v-else-if="areYouSureType.eType === 'updateMailedDate'"
            #overlay
          >
            <div>
              <div class="mb-5 text-2xl text-center underline text-danger">
                <strong
                  :id="
                    `${areYouSureType.data.item.filename}-form-confirm-label w-full`
                  "
                  >WARNING !! ARE YOU SURE YOU WANT TO UPDATE YOUR MAILED DATE
                  ?</strong
                >
              </div>
              <div class="w-full mb-2 text-xl text-center text-warning">
                <strong
                  :id="
                    `${areYouSureType.data.item.filename}-form-confirm-label w-full`
                  "
                >
                  <h4 class="mb-2 text-gray-800">
                    THIS MAILING IS MARKED UNDER YOUR
                    {{ $store.getters["auth/accountTypeAllCaps"] }}'S COMPLIANCE
                    RECORDS! Please make sure you mark the actual date that you
                    have mailed, or plan on mailing your letters. If your dates
                    do not match with acutal mailing dates you will no longer be
                    in compliance with accurate record keeping as required by
                    State and Federal authorities. Furthermore, your future
                    exports will be filtered and sorted based on these dates.
                    THIS MEANS YOUR FUTURE EXPORTS MAY EXCLUDE THESE RECORDS OR
                    FILTER AND SORT INCORRECTLY!
                  </h4>
                  <p class="italic">
                    ** IT IS CRUCIAL THAT YOU ONLY SELECT YES IF THE DATE
                    SELECTED IS THE CORRECT.
                  </p>
                </strong>
              </div>
              <div class="">
                <b-button
                  size="lg"
                  variant="outline-danger"
                  class="mr-3"
                  @click="showAreYouSure = false"
                >
                  NO GO BACK!
                </b-button>

                <b-button
                  size="lg"
                  variant="outline-success"
                  @click="updateMailedDate(areYouSureType.data)"
                  >YES</b-button
                >
              </div>
            </div>
          </template>
          <export-table
            v-if="expanded"
            :filename="filename"
            :view-only="true"
            :expanded="true"
            :packed-filters="packedFilters"
            :response-data="responseData"
            :table-name="tableName"
            :initial-load="false"
            @contract="contract"
            @viewExports="viewExports"
          />
          <b-table
            v-else
            ref="table"
            class="w-full mt-1 mb-0 border rounded-lg min-h-6xl"
            no-local-sorting
            show-empty
            responsive
            :items="backendCall"
            :fields="fields"
            :tbody-tr-class="rowClass"
            :per-page="perPage"
            :busy.sync="isBusy"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @filtered="onFiltered"
            @sort-changed="onFiltered"
          >
            <template #head(mailtypes)="data">
              <div
                class="flex flex-row items-center justify-between w-full space-x-1"
              >
                <div
                  v-for="item in [
                    'Garnish',
                    'Disposed',
                    'Hearing',
                    'Served',
                    'Filed'
                  ]"
                  :key="item"
                  class="flex flex-col w-24 text-center "
                >
                  <b-button
                    variant="gradient-secondary"
                    size="sm"
                    @click="areYouSure(data, false)"
                  >
                    <div
                      class="flex flex-row items-center justify-center text-center"
                    >
                      <div class="">{{ item }}</div>
                    </div>
                  </b-button>
                </div>
              </div>
            </template>

            <template #cell(download)="data">
              <div v-if="data.item.user" class="w-full text-xs">
                export by: <span class="font-bold">{{ data.item.user }}</span>
              </div>
              <div v-else class="w-full text-xs">
                <span class="font-bold text-success">my export</span>
              </div>
              <b-button
                v-if="
                  fNI[data.item.filename] ? fNI[data.item.filename].i : false
                "
                variant="gradient-primary"
                class="w-32 pr-1 shadow-md btn-icon"
                size="sm"
                @click="
                  updateFilename(
                    data.item.filename,
                    filenameUpdates[data.item.filename],
                    data
                  )
                "
              >
                <div class="flex flex-row items-center justify-center">
                  <feather-icon icon="DownloadIcon" />
                  <div class="px-1 text-xs">SAVE</div>
                </div>
              </b-button>
              <b-button
                v-else
                variant="gradient-secondary"
                class="w-32 shadow-md btn-icon"
                size="sm"
                @click="download(data.item.filename)"
              >
                <div class="flex flex-row items-center justify-center">
                  <feather-icon icon="DownloadIcon" />
                  <div class="text-xs">DOWNLOAD</div>
                </div>
              </b-button>
            </template>

            <template #cell(filename)="data">
              <div class="truncate text-md">{{ data.item.filename }}</div>
              <!-- <div class="flex flex-row items-center justify-items-center">
                <div
                  v-if="
                    fNI[data.item.filename] ? fNI[data.item.filename].fP : false
                  "
                >
                  <feather-icon class="text-success" icon="CheckCircleIcon" />
                </div>
                <div v-else-if="data.item.filename === fileName">
                  <b-badge
                    class="absolute text-xs -top-3 left-2"
                    variant="success"
                    >New</b-badge
                  >
                  <feather-icon class="text-success" icon="EditIcon" />
                </div>
                <div
                  v-else-if="
                    fNI[data.item.filename] ? fNI[data.item.filename].fF : false
                  "
                >
                  <feather-icon class="text-danger" icon="XCircleIcon" />
                </div>

                <div v-else>
                  <feather-icon class="text-success" icon="EditIcon" />
                </div>

                <feather-icon
                  class="invisible text-white"
                  icon="CheckCircleIcon"
                />
                <b-form-input
                  :id="`popover-button-sync-${data.item.filename}`"
                  v-model="filenameUpdates[data.item.filename]"
                  size="sm"
                  :placeholder="`${data.item.filename}`"
                  :class="
                    filenameCSSTest[data.item.filename]
                      ? filenameCSSTest[data.item.filename]
                      : {}
                  "
                  @click="syncError[data.item.filename] = false"
                  @input="filenameInput(data.item.filename)"
                  @blur="handleBlur(data.item.filename)"
                ></b-form-input>

                <b-popover
                  :show.sync="syncError[data.item.filename]"
                  :target="`popover-button-sync-${data.item.filename}`"
                  variant="danger"
                  triggers="focus"
                  :disabled="syncError[data.item.filename] ? false : true"
                >
                  <template #title>Oops!</template>
                  This filename already exists; please choose another filename.
                </b-popover>
              </div> -->
            </template>

            <template #cell(exportdate)="data">
              <div class="truncate text-md">
                {{ data.item.exportdate }}
              </div>
            </template>
            <template #cell(mailed)="data">
              <div class="truncate text-md">
                {{ data.item.mailed.mailed_date }}
              </div>
              <!-- <div class="">
                <div
                  v-if="data.item.mailed.mailed"
                  class="flex flex-row items-center justify-center"
                >
                  <b-form-checkbox
                    v-model="tempTableData[data.index].mailed.mailed"
                    switch
                    @change="areYouSureMailed(data)"
                  ></b-form-checkbox>
                  <b-input-group label-for="to-input-mailed-date" class="mb-0">
                    <b-form-datepicker
                      v-model="tempTableData[data.index].mailed.mailed_date"
                      size="sm"
                      button-variant="gradient-secondary"
                      show-decade-nav
                      button-only
                      right
                      locale="en-US"
                      aria-controls="to-input-mailed-date"
                      @input="areYouSureMailedDate(data)"
                      ><template #button-content>
                        <feather-icon icon="CalendarIcon"/></template
                    ></b-form-datepicker>
                  </b-input-group>
                </div>
                <div v-else class="flex flex-row items-center justify-center">
                  <b-form-checkbox
                    v-model="tempTableData[data.index].mailed.mailed"
                    switch
                    @change="areYouSureMailed(data)"
                  ></b-form-checkbox>

                  <b-input-group
                    label-for="to-input-mailed-date"
                    disabled
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-form-datepicker
                      v-model="tempTableData[data.index].mailed.mailed_date"
                      disabled
                      size="sm"
                      button-variant="gradient-secondary"
                      show-decade-nav
                      button-only
                      right
                      locale="en-US"
                      aria-controls="to-input-mailed-date"
                      @input="areYouSureMailedDate(data)"
                      ><template #button-content>
                        <feather-icon icon="CalendarIcon"/></template
                    ></b-form-datepicker>
                  </b-input-group>
                </div>
              </div> -->
            </template>
            <template #cell(mailtypes)="data">
              <div
                class="flex flex-row items-center justify-between w-full space-x-1"
              >
                <div
                  v-for="item in data.item.mailtypes.mail_types"
                  :key="item.key"
                  class="flex flex-col w-24 text-center "
                >
                  <b-button
                    v-if="!item.e"
                    disabled
                    variant="gradient-secondary"
                    class="text-gray-500 shadow-none cursor-default btn-icon"
                    size="sm"
                  >
                    <div class="flex flex-row items-center justify-center">
                      <div>view</div>
                      <div class="ml-1">0</div>
                    </div>
                  </b-button>

                  <b-button
                    v-else-if="
                      item.e &&
                        data.item.mailtypes.filter_mail_types.includes(item.key)
                    "
                    variant="gradient-success"
                    class="shadow-md btn-icon"
                    size="sm"
                    @click="viewExports(item, data)"
                  >
                    <div class="flex flex-row items-center justify-center">
                      <div>view</div>
                      <div class="ml-1">{{ item.count }}</div>
                    </div>
                  </b-button>
                  <b-button
                    v-else
                    variant="gradient-primary"
                    class="shadow-md btn-icon"
                    size="sm"
                    @click="viewExports(item, data)"
                  >
                    <div class="flex flex-row items-center justify-center">
                      <div>view</div>
                      <div class="ml-1">{{ item.count }}</div>
                    </div>
                  </b-button>
                </div>
              </div>
            </template>
            <template #table-busy>
              <div
                class="flex flex-col items-center justify-center my-2 text-center h-72 text-danger "
              >
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-overlay>
      </export-overlay>
    </div>
  </div>
</template>



<script>
import moment from "moment"
import ExportTable from "../../forms/form-wizard/ExportTable.vue"
import {
  // BFormCheckbox,
  //BBadge,
  BOverlay,
  //BPopover,
  // BFormDatepicker,
  //BInputGroup,
  BSpinner,
  BTable,
  BPagination,
  BFormGroup,
  BFormSelect,
  BButton
  //BFormInput
} from "bootstrap-vue"
import ExportOverlay from "../../forms/form-wizard/ExportOverlay.vue"
export default {
  components: {
    ExportTable,
    //BFormCheckbox,
    //BBadge,
    BOverlay,
    //BPopover,
    ExportOverlay,
    //BFormDatepicker,
    //BInputGroup,
    BSpinner,
    BTable,
    BPagination,
    BFormGroup,
    BFormSelect,
    BButton
    //BFormInput
  },
  props: {
    users: {
      type: String,
      required: true
    },
    fileName: {
      type: String,
      required: true
    },
    filterBy: {
      type: String,
      required: true
    },
    filter: {
      type: String,
      required: true
    },
    includes: {
      type: Array,
      required: true
    },
    mailDate: {
      type: Object,
      required: true
    },
    exportDate: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      filename: "",
      responseData: {},
      index: 0,
      tableName: "",
      packedFilters: {},
      expanded: false,
      areYouSureType: { eType: "", data: {} },
      showAreYouSure: false,
      syncError: {},
      // TEMP TABLE DATA
      tempTableData: [],
      tempTable: false,
      // FILENAME INPUT
      fNI: {},
      // FILENAME PASS
      fP: {},
      // FILENAME FAIL
      fF: {},
      // TEST CSS
      filenameCSSTest: {},
      busy: false,
      heading: "DOWNLOADING",
      busyMessage: "",
      addedMailedDate: {},
      mailedDate: {},
      filenameUpdates: {},
      dataUpdates: {},
      sortBy: "exportdate",
      sortDesc: true,

      currentPage: 1,
      perPage: 7,
      totalRows: 0,
      initialLoad: true,
      exportFilters: {},
      isBusy: false,

      // SELECTION OPTIONS

      pageOptions: [5, 7, 10, 15, { value: 100, text: "Show a lot" }],
      items: [],
      fields: [
        {
          key: "download",
          label: "Download"
        },

        {
          key: "filename",
          label: "File Name",
          sortable: true,
          sortDirection: "desc"
        },

        {
          key: "exportdate",
          label: "Export Date",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "mailed",
          label: "Mailed",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "mailtypes",
          label: "View Export Details"
        }
      ]
    }
  },

  watch: {
    users() {
      this.$refs.table.refresh()
    },
    filterBy() {
      this.$refs.table.refresh()
    },
    filter() {
      this.$refs.table.refresh()
    },
    includes() {
      this.$refs.table.refresh()
    },
    mailDate() {
      this.$refs.table.refresh()
    },
    exportDate() {
      this.$refs.table.refresh()
    }
  },
  created() {},
  methods: {
    contract() {
      this.expanded = false

      this.tempTable = true
    }, // eslint-disable-next-line no-unused-vars
    areYouSure(data, bool) {
      this.showAreYouSure = true
      this.$refs.table.refresh()
      this.areYouSureType = { eType: "deleteExport", data: data }
    },
    areYouSureMailed(data) {
      this.showAreYouSure = true
      this.areYouSureType = { eType: "updateMailed", data: data }
    },
    areYouSureMailedDate(data) {
      this.showAreYouSure = true
      this.areYouSureType = { eType: "updateMailedDate", data: data }
    },
    packageQuery() {
      return {
        sort: {
          sortBy: this.sortBy,
          sortDesc: this.sortDesc
        },
        filter: {
          filterBy: this.filterBy,
          filter: this.filter
        },
        includes: this.includes,
        dates: {
          mailDate: this.mailDate,
          exportDate: this.exportDate
        }
      }
    },
    backendCall(ctx, callback) {
      const l1 = this

      //if (l1.justContract) {
      //  l1.justContract = false
      //  return
      //}

      if (!l1.tempTable) {
        var x = (ctx.currentPage - 1) * this.perPage
        if (x < 0) {
          x = x - 1
        }
        ctx["limit"] = ctx.perPage
        ctx["offset"] = x

        var query = l1.packageQuery()
        query["ctx"] = ctx

        l1.$AuthAxios.instance
          .get("/api-access/exports/get-export-table-data/", {
            params: {
              exportHistory: query,
              compliance: true,
              admin: this.$can("get", "accountAdmin"),
              userFilter: this.users
            }
          })
          .then(function(res) {
            l1.tempTableData = res.data.results

            l1.totalRows = res.data.count
            callback(res.data.results)
          })
          .catch((error) => {
            console.log(error)
          })
        return null
      } else {
        l1.tempTable = false
        callback(l1.tempTableData)
      }
      return null
    },
    async download(filename) {
      var l1 = this
      filename = l1.filenameUpdates[filename]
        ? l1.filenameUpdates[filename]
        : filename

      l1.busyMessage = `Your file: ${filename}.zip will be ready soon!`
      l1.busy = true

      await l1.$AuthAxios.instance
        .get(
          "/api-access/exports/generate-export/",
          {
            params: {
              filename: filename
            }
          },
          {
            responseType: "arraybuffer",
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        )
        .then(
          await function(res) {
            l1.busy = false
            function str2bytes(str) {
              var bytes = new Uint8Array(str.length)
              for (var i = 0; i < str.length; i++) {
                bytes[i] = str[i]
              }
              return bytes
            }

            var fileURL = window.URL.createObjectURL(
              new Blob([str2bytes(res.data.data)], { type: "application/zip" })
            )
            var fileLink = document.createElement("a")

            fileLink.href = fileURL
            fileLink.setAttribute(
              "download",
              `${res.config.params.filename}.zip`
            )
            document.body.appendChild(fileLink)

            fileLink.click()
          }
        )
        .catch(function(error) {
          console.log(error)
          l1.busy = false
        })
    },
    handleBlur(filename) {
      if (!this.filenameUpdates[filename]) {
        this.tempTable = true
        this.fNI[filename] = {}
        //this.filenameCSSTest[filename] = {
        //  "border-2": false
        //}
        this.fNI[filename].i = false
        this.fNI[filename].fF = false
        this.fNI[filename].fP = false

        this.filenameCSSTest[filename] = {}

        this.$refs.table.refresh()
      }
    },

    async viewExports(item, data, filters = false) {
      var l1 = this
      l1.isBusy = true
      await l1.$AuthAxios.instance
        .get("/api-access/exports/view-exports/", {
          params: {
            key: item.key,
            filename: data.item.filename,
            filters: filters
          }
        })
        .then(
          await function(res) {
            l1.isBusy = false
            l1.filename = data.item.filename
            l1.viewOnly = true
            l1.responseData = res.data
            l1.tableName = res.data.key
            l1.initialLoad = true

            l1.expanded = true
          }
        )
        .catch(function(error) {
          console.log(error)
        })
    }, // eslint-disable-next-line no-unused-vars
    dateFormat(value, event) {
      return moment(value).format("YYYY-MM-DD")
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    rowClass(item, type) {
      if (!item || type !== "row") return "relative"
      if (item.filename === this.fileName) return "relative table-success"
    }
  }
}
</script>

